/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export default function Avis() {
  const settings = {
    //className: "slider variable-width",
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };

  return (
    <>
      <div className="pt-8 lg:pb-16 bg-art-beige">
        <div className="px-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="flex relative items-center py-5 px-6 space-x-3 bg-white rounded-lg border border-gray-300 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400 w-2xl">
            <div className="flex-shrink-0">
              <StaticImage
                className="object-cover object-center"
                src="../../images/google-logo.png"
                alt="Avis Google 1"
                height={100}
              />
            </div>
            <div className="flex-1 items-center min-w-0">
              <span className="absolute inset-0" aria-hidden="true" />
              <p className="text-base font-medium text-gray-900">
                Art Moval Déménagements
              </p>
              <p className="text-sm text-gray-500 truncate">
                76 Av. Charles de Gaulle
              </p>
              <p className="text-sm text-gray-500 truncate">
                69160 Tassin-la-Demi-Lune
              </p>
              <ul className="flex mb-0">
                <li className="pr-2 text-base">4,6</li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                    className="w-5 text-warning"
                  >
                    <path
                      fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                    className="w-5 text-warning"
                  >
                    <path
                      fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                    className="w-5 text-warning"
                  >
                    <path
                      fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                    className="w-5 text-warning"
                  >
                    <path
                      fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                    className="w-5 text-warning"
                  >
                    <path
                      fill="currentColor"
                      d="m480 757 157 95-42-178 138-120-182-16-71-168v387ZM233 976l65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                    />
                  </svg>
                </li>
                <li className="pl-2 text-base">162 avis Google</li>
              </ul>
            </div>
          </div>
          <div className="block w-full md-hidden">
            <Slider
              {...settings}
              className="px-4 mx-auto mt-4 w-full align-middle text-art-marron-fonce"
            >
              <div className="flex justify-center items-center p-4">
                <div className="mb-12 md:mb-0">
                  <div className="flex justify-center mb-6">
                    <StaticImage
                      className="rounded-full shadow-lg dark:shadow-black/20"
                      src="../../images/rossi.png"
                      alt="Avis Google 1"
                    />
                  </div>
                  <p className="mb-2 text-lg font-normal">Sandra ROSSI</p>
                  <p className="mb-4 text-sm">
                    Prestation de qualité tant au niveau de la prise en charge,
                    du suivi et de la mise en oeuvre de mon déménagement. Ecoute
                    des besoins clients, ponctualité, organisation, efficacité
                    et disponibilité ont été honorés. Un grand merci à toute
                    l'équipe !
                  </p>
                  <ul className="flex justify-center mb-0">
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 96 960 960"
                        className="w-5 text-warning"
                      >
                        <path
                          fill="currentColor"
                          d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                        />
                      </svg>
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 96 960 960"
                        className="w-5 text-warning"
                      >
                        <path
                          fill="currentColor"
                          d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                        />
                      </svg>
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 96 960 960"
                        className="w-5 text-warning"
                      >
                        <path
                          fill="currentColor"
                          d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                        />
                      </svg>
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 96 960 960"
                        className="w-5 text-warning"
                      >
                        <path
                          fill="currentColor"
                          d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                        />
                      </svg>
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 96 960 960"
                        className="w-5 text-warning"
                      >
                        <path
                          fill="currentColor"
                          d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                        />
                      </svg>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="flex justify-center items-center p-4">
                <div className="mb-12 md:mb-0">
                  <div className="flex justify-center mb-6">
                    <StaticImage
                      className="rounded-full shadow-lg dark:shadow-black/20"
                      src="../../images/geoffroy.png"
                      alt="Avis Google 1"
                    />
                  </div>
                  <p className="mb-2 text-lg font-normal">Geoffroy</p>

                  <p className="mb-4 text-sm">
                    Nous avons contacté plusieurs sociétés de déménagement. Nous
                    ne regrettons pas d'avoir rencontré Art moval : Rapport
                    qualité/prix, réactivité, professionnalisme, personnels
                    sympa (commercial, administration, déménageurs). Nous
                    recommandons vivement Art Moval!!!
                  </p>
                  <ul className="flex justify-center mb-0">
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 96 960 960"
                        className="w-5 text-warning"
                      >
                        <path
                          fill="currentColor"
                          d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                        />
                      </svg>
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 96 960 960"
                        className="w-5 text-warning"
                      >
                        <path
                          fill="currentColor"
                          d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                        />
                      </svg>
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 96 960 960"
                        className="w-5 text-warning"
                      >
                        <path
                          fill="currentColor"
                          d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                        />
                      </svg>
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 96 960 960"
                        className="w-5 text-warning"
                      >
                        <path
                          fill="currentColor"
                          d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                        />
                      </svg>
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 96 960 960"
                        className="w-5 text-warning"
                      >
                        <path
                          fill="currentColor"
                          d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                        />
                      </svg>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="flex justify-center items-center p-4">
                <div className="mb-12 md:mb-0">
                  <div className="flex justify-center mb-6">
                    <StaticImage
                      className="rounded-full shadow-lg dark:shadow-black/20"
                      src="../../images/gast.png"
                      alt="Avis Google 1"
                    />
                  </div>
                  <p className="mb-2 text-lg font-normal">L'Ilot Gast</p>

                  <p className="mb-4 text-sm">
                    Après la visite du sympathique commercial, mon devis m'a été
                    transmis en 48h. Un déménagement qui s’est parfaitement
                    déroulé. Pas de casse grâce au professionnalisme des
                    déménageurs. Ponctuels, rapides et efficaces mais aussi
                    d'une grande sympathie et de bienveillance. Un grand merci
                    pour le travail effectué par l'ensemble des collaborateurs
                    d'ARTMOVAL ! Je recommande vivement !
                  </p>
                  <ul className="flex justify-center mb-0">
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 96 960 960"
                        className="w-5 text-warning"
                      >
                        <path
                          fill="currentColor"
                          d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                        />
                      </svg>
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 96 960 960"
                        className="w-5 text-warning"
                      >
                        <path
                          fill="currentColor"
                          d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                        />
                      </svg>
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 96 960 960"
                        className="w-5 text-warning"
                      >
                        <path
                          fill="currentColor"
                          d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                        />
                      </svg>
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 96 960 960"
                        className="w-5 text-warning"
                      >
                        <path
                          fill="currentColor"
                          d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                        />
                      </svg>
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 96 960 960"
                        className="w-5 text-warning"
                      >
                        <path
                          fill="currentColor"
                          d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                        />
                      </svg>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="flex justify-center items-center p-4">
                <div className="mb-12 md:mb-0">
                  <div className="flex justify-center mb-6">
                    <StaticImage
                      className="rounded-full shadow-lg dark:shadow-black/20"
                      src="../../images/potiquet.png"
                      alt="Avis Google 1"
                    />
                  </div>
                  <p className="mb-2 text-lg font-normal">Vero Potiquet</p>

                  <p className="mb-4 text-sm">
                    Accueil parfait, réactivité, mise en place des devis,
                    réponses rapides, prêt de matériel + ponctualité de l'équipe
                    à l'heure déterminée, professionnalisme, efficacité. Tout
                    parfait !
                  </p>
                  <ul className="flex justify-center mb-0">
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 96 960 960"
                        className="w-5 text-warning"
                      >
                        <path
                          fill="currentColor"
                          d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                        />
                      </svg>
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 96 960 960"
                        className="w-5 text-warning"
                      >
                        <path
                          fill="currentColor"
                          d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                        />
                      </svg>
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 96 960 960"
                        className="w-5 text-warning"
                      >
                        <path
                          fill="currentColor"
                          d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                        />
                      </svg>
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 96 960 960"
                        className="w-5 text-warning"
                      >
                        <path
                          fill="currentColor"
                          d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                        />
                      </svg>
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 96 960 960"
                        className="w-5 text-warning"
                      >
                        <path
                          fill="currentColor"
                          d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                        />
                      </svg>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="flex justify-center items-center p-4">
                <div className="mb-12 md:mb-0">
                  <div className="flex justify-center mb-6">
                    <StaticImage
                      className="rounded-full shadow-lg dark:shadow-black/20"
                      src="../../images/rpm.png"
                      alt="Avis Google 1"
                    />
                  </div>
                  <p className="mb-2 text-lg font-normal">Guillaume Rpm</p>

                  <p className="mb-4 text-sm">
                    Mission : transférer un billard avec ardoise monobloc.
                    L’équipe a exécuté l’opération en un temps record. Billard
                    parfaitement emballé et transporté avec soin. 3 eme étage
                    sans ascenseur et avec le sourire..! Je recommande
                    fortement. Encore merci à Margaux et son équipe pour votre
                    professionnalisme !
                  </p>
                  <ul className="flex justify-center mb-0">
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 96 960 960"
                        className="w-5 text-warning"
                      >
                        <path
                          fill="currentColor"
                          d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                        />
                      </svg>
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 96 960 960"
                        className="w-5 text-warning"
                      >
                        <path
                          fill="currentColor"
                          d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                        />
                      </svg>
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 96 960 960"
                        className="w-5 text-warning"
                      >
                        <path
                          fill="currentColor"
                          d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                        />
                      </svg>
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 96 960 960"
                        className="w-5 text-warning"
                      >
                        <path
                          fill="currentColor"
                          d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                        />
                      </svg>
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 96 960 960"
                        className="w-5 text-warning"
                      >
                        <path
                          fill="currentColor"
                          d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                        />
                      </svg>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="flex justify-center items-center p-4">
                <div className="mb-12 md:mb-0">
                  <div className="flex justify-center mb-6">
                    <StaticImage
                      className="rounded-full shadow-lg dark:shadow-black/20"
                      src="../../images/clerc.png"
                      alt="Avis Google 1"
                    />
                  </div>
                  <p className="mb-2 text-lg font-normal">Valentin Clerc</p>
                  <p className="mb-4 font-medium text-primary dark:text-primary-400">
                    Local Guide - Niveau 5
                  </p>
                  <p className="mb-4 text-sm">
                    Je vois beaucoup d'avis négatifs concernant la partie
                    "relais colis" de cette société. Les avis devraient
                    concerner leur activité principale, LE DÉMÉNAGEMENT ! Art
                    Moval est une excellente société de déménagement, j'ai fait
                    appel à eux pour mon dernier déménagement suite à la
                    recommandation d'un ami. Leur commercial Jules est dynamique
                    et très efficace ! Les déménageurs ont été très efficaces,
                    merci à eux.
                  </p>
                  <ul className="flex justify-center mb-0">
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 96 960 960"
                        className="w-5 text-warning"
                      >
                        <path
                          fill="currentColor"
                          d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                        />
                      </svg>
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 96 960 960"
                        className="w-5 text-warning"
                      >
                        <path
                          fill="currentColor"
                          d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                        />
                      </svg>
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 96 960 960"
                        className="w-5 text-warning"
                      >
                        <path
                          fill="currentColor"
                          d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                        />
                      </svg>
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 96 960 960"
                        className="w-5 text-warning"
                      >
                        <path
                          fill="currentColor"
                          d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                        />
                      </svg>
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 96 960 960"
                        className="w-5 text-warning"
                      >
                        <path
                          fill="currentColor"
                          d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                        />
                      </svg>
                    </li>
                  </ul>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}
